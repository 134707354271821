// import "../../styles/style.scss";
import "./needHelp.css";
import {Link} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function NeedHelp(props) {
    const navigate = useNavigate();
    const { needHelp } = props;
    const [helpData, setHelpData] = useState('');

    useEffect(() => {
        if (needHelp.length > 0) {
            const content = needHelp[0].content.rendered;
            setHelpData(content);
        }
    }, [needHelp]);

    const handleLinkClick = (event) => {
        // Check if the clicked element or its parents are a link
        const anchor = event.target.closest('a');
        if (anchor && anchor.href) {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Assuming the WP site is at 'https://devc.l4sb.com'
            const internalPath = anchor.getAttribute('href');
            if (internalPath.startsWith('/')) {
                navigate(internalPath);
            }
        }
    };

    return (
        <div className={"pageView"} onClick={handleLinkClick}>
            <div className="helpPageWrapper">
                <Typography variant="h4" style={{fontWeight: 600}}>Help Page</Typography>
                <div dangerouslySetInnerHTML={{ __html: helpData }} />
            </div>
        </div>
    );
}

//
// export default function NeedHelp(props){
//     const wp_site_url = process.env.REACT_APP_WP_SITE_URL;
//     const needHelpData = props.needHelp;
//
//     const [helpData, setHelpData] = useState('');
//
//     useEffect(() => {
//         const content = needHelpData[0].content.rendered;
//         setHelpData(content);
//     }, [needHelpData]);
//
//     return (
//         <div className={"pageView"}>
//             <div className="helpPageWrapper">
//                 {/*<h1>Help Page</h1>*/}
//                 <Typography variant="h4" style={{fontWeight: 600}}>Help Page</Typography>
//                 <div dangerouslySetInnerHTML={{ __html: helpData }} />
//             </div>
//         </div>)
// }
import React from "react";
import { Box, Typography } from '@mui/material';
import {Link} from "react-router-dom";
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import InventoryIcon from '@mui/icons-material/Inventory';
import PendingIcon from '@mui/icons-material/Pending';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Chip = styled(Box)(({ theme, backgroundColor }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: "6px 15px",
    borderRadius: '20px', // Change as needed
    color: "white",
    backgroundColor: backgroundColor,
    '& .MuiTypography-root': {
        marginRight: theme.spacing(1), // Adjust spacing as needed
        fontWeight: "600 !important",
        letterSpacing: "0.0938em !important",
    },
}));

export const SubChip = styled(Chip)({
    padding: '2px 15px',
    '& .MuiTypography-root': {
        fontSize: '0.8em',
    },
});

export const statusColorMap = {
    ACTIVE: '#c6e1c6',
    PENDING: '#c6e1c6',
    OABANNED: '#dd3333',
    INACTIVE: '#dd3333',
    PROCESSING: '#c6e1c6',
    INPROGRESS: '#6d98bf',
    COMPLETED: '#c8d7e1',
    ONHOLD: '#f8dda7',
    CANCELLED: '#e5e5e5',
    REFUNDED: '#e5e5e5',
    FAILED: '#eba3a3',
    EXPIRED: '#bd94ae',
    DONE: '#c8d7e1'
};

export const getStatusColor = (status) => {
    return statusColorMap[status] || 'black'; // return black as default color if status not in map
};

export const statusIconMap = {
    ACTIVE: VerifiedIcon,
    PENDING: AccessTimeFilledIcon,
    OABANNED: NotInterestedIcon,
    INACTIVE: InventoryIcon,
    PROCESSING: PendingIcon,
    INPROGRESS: HourglassTopIcon,
    COMPLETED: CheckCircleIcon,
    ONHOLD: RemoveCircleIcon,
    CANCELLED: CancelIcon,
    REFUNDED: ChangeCircleIcon,
    FAILED: ErrorIcon,
    EXPIRED: HighlightOffIcon,
    DONE: CheckCircleIcon
}

export const getStatusIcon = (status) => {
    return statusIconMap[status] || AcUnitIcon;
}

export function MyChip({ label , icon: Icon, color }) {
    return (
        <Chip backgroundColor={color} style={{width: 'fit-content'}}>
            <Typography className="MuiTypography-root">{label}</Typography>
            <Icon />
        </Chip>
    );
}

export function MyExpansionChip({ label , icon: Icon, color }) {
    return (
        <SubChip backgroundColor={color} style={{width: 'fit-content'}}>
            <Typography className="MuiTypography-root">{label}</Typography>
            <Icon />
        </SubChip>
    );
}

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: '#f7f9fc', // Slightly off-white background
    color: '#536889', // Main heading text color
    padding: '10px 16px',
    borderBottom: '1px solid #ddd',
    flexDirection: 'row-reverse', // This swaps the icon and text positions
    '& .MuiAccordionSummary-expandIconWrapper': {
        marginRight: '8px', // Add margin between the icon and text
        transform: 'rotate(0deg)', // Default to pointing right
        transition: 'transform 0.3s ease',
    },
    '&.Mui-expanded .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(90deg)', // Rotate downwards when expanded
    },
    '& .MuiTypography-root': {
        fontWeight: 600, // Bold font for the heading
        fontSize: '1.1rem', // Medium font size for better readability
    },
    '&:hover': {
        backgroundColor: '#f0f0f0', // Subtle hover effect
    },
}));


export const StyledAccordionDetails = styled(AccordionDetails)({
    backgroundColor: '#f0f0f0',
    padding: '16px',
});

// // Custom-styled AccordionSummary for consistent styling
// export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
//     backgroundColor: '#f7f9fc', // Slightly off-white background
//     color: '#536889', // Main heading text color
//     padding: '10px 16px',
//     borderBottom: '1px solid #ddd',
//     '& .MuiTypography-root': {
//         fontWeight: 600, // Bold font for the heading
//         fontSize: '1.1rem', // Medium font size for better readability
//     },
//     '&:hover': {
//         backgroundColor: '#f0f0f0', // Subtle hover effect
//     },
// }));



// File: OrderUtils.js

// Helper function for formatting addresses
const formatAddress = (address) => {
    if (!address) return <span>Address not provided</span>;
    const { address_1, address_2, city, state, zip_code, country } = address;
    return (
        <div>
            {address_1 && <div>{address_1}</div>}
            {address_2 && <div>{address_2}</div>}
            <div>
                {city && <span>{city}, </span>}
                {state && <span>{state} </span>}
                {zip_code && <span>{zip_code}</span>}
                {country && <span>, {country}</span>}
            </div>
        </div>
    );
};

// Function to get the card brand and last 4 digits for the subscription
export const getCardDetailsBySubscriptionId = (subscriptionId, creditCards) => {
    if (!subscriptionId || !creditCards) return null;
    const matchedCard = creditCards.find(card => {
        const subscriptions = card.subscriptions;
        // Check if the "subscriptions" field contains the subscription ID
        return Array.isArray(subscriptions) && subscriptions.includes(subscriptionId);
    });
    return matchedCard ? `${matchedCard.method.brand} ending in ${matchedCard.method.last4}` : null;
};


export const relatedSubscriptions = (subList, subs, creditCards) => {
    subs = subs || [];

    if (subs.length === 0) return null;

    const filteredSubscriptions = subList.filter(subscription => subs.includes(subscription.id));

    if (filteredSubscriptions.length === 0) return null;

    return (
        <Accordion>
            <StyledAccordionSummary
                expandIcon={<ChevronRightIcon  />}
                aria-controls="related-subscriptions-content"
                id="related-subscriptions-header"
            >
                <Typography variant="h6">Related Subscriptions</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails style={{ backgroundColor: '#f0f0f0', padding: '16px' }}>
                <div className={"serviceMetaData"}>
                    <ul className={"metaDataList"}>
                        <li>
                            <span><strong>Subscription ID</strong></span>
                        </li>
                        <li>
                            <span><strong>Service(s)</strong></span>
                        </li>
                        <li>
                            <span><strong>Renewal Date</strong></span>
                        </li>
                        <li>
                            <span><strong>Payment Method</strong></span>
                        </li>
                    </ul>
                    {filteredSubscriptions.map(item => {
                        const { id: subId, line_items, next_payment_date_gmt, payment_method_title } = item;
                        const renewalDateForSub = next_payment_date_gmt ? new Date(next_payment_date_gmt).toLocaleDateString() : "N/A";

                        // Get the correct payment method for this subscription
                        const paymentMethodDetails = getCardDetailsBySubscriptionId(subId.toString(), creditCards) || "N/A";
//style={{ paddingBottom: '8px' }}
                        return (
                            <ul className={"metaDataList"} key={`related-subscription-${subId}`} >
                                <li>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Link to={`/view-subscription/${subId}`} style={{ color: '#536889', marginRight: '10px' }}>
                                            <span className={"idNamelink"}>{subId}</span>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    {line_items.map(e => (
                                        <span key={e.id}>{e.name}<br /></span>
                                    ))}
                                </li>
                                <li>
                                    <span>{renewalDateForSub}</span>
                                </li>
                                <li>
                                    <span>{paymentMethodDetails}</span>
                                </li>
                            </ul>
                        );
                    })}
                </div>
            </StyledAccordionDetails>
        </Accordion>
    );
};



export const RelatedOrders = ({ orderList, relatedOrders }) => {
    let relatedOrdersArray = [];

    if (Array.isArray(relatedOrders)) {
        relatedOrdersArray = relatedOrders;
    } else if (typeof relatedOrders === 'number') {
        relatedOrdersArray = [relatedOrders];
    } else if (relatedOrders && typeof relatedOrders === 'object') {
        relatedOrdersArray = Object.keys(relatedOrders).map(key => parseInt(relatedOrders[key]));
    }

    const relatedOrderDetails = orderList.filter(order =>
        relatedOrdersArray.includes(parseInt(order.id))
    );

    if (relatedOrderDetails.length === 0) return null;

    return (
        <Accordion>
            <StyledAccordionSummary
                expandIcon={<ChevronRightIcon  />}
                aria-controls="related-orders-content"
                id="related-orders-header"
            >
                <Typography variant="h6">Related Orders</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails style={{ backgroundColor: '#f0f0f0', padding: '16px' }}>
                <div className={"serviceMetaData"}>
                <ul className={"metaDataList"}>
                    <li>
                        <span><strong>Order ID</strong></span>
                    </li>
                    <li>
                        <span><strong>Service(s)</strong></span>
                    </li>
                    <li>
                        <span><strong>Order Date</strong></span>
                    </li>
                    <li>
                        <span><strong>Payment Method</strong></span>
                    </li>
                </ul>
                {relatedOrderDetails.map(item => {
                    const { id: orderID, status, payment_method_title, date_created, line_items } = item;
                    const orderDate = new Date(date_created).toLocaleDateString();
                    const relatedStatus = status.replace(/-/g, "").toUpperCase();
                    const relatedStatusColor = getStatusColor(relatedStatus);
                    const RelatedIcon = getStatusIcon(relatedStatus);

                    const relatedOrderStatusTooltip = (
                        <Tooltip title={relatedStatus}>
                            <RelatedIcon style={{ color: relatedStatusColor }} />
                        </Tooltip>
                    );
//style={{ paddingBottom: '8px' }}
                    return (
                        <ul className={"metaDataList"} key={`related-order-${orderID}`} >
                            <li>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Link to={`/view-order/${orderID}`} style={{ color: '#536889', marginRight: '10px' }}>
                                        <span className={"idNamelink"}>{orderID}</span>
                                    </Link>
                                    {relatedOrderStatusTooltip}
                                </div>
                            </li>
                            <li>
                                {line_items.map(e => (
                                    <span key={e.id}>{e.name}<br /></span>
                                ))}
                            </li>
                            <li>
                                <span>{orderDate}</span>
                            </li>
                            <li>
                                <span>{payment_method_title}</span>
                            </li>
                        </ul>
                    );
                })}
                </div>
            </StyledAccordionDetails>
        </Accordion>
    );
};

export const ForeignRegistrations = ({ foreignRegistrations, companyTitle, companyId }) => {
    return (
        <Accordion>
            <StyledAccordionSummary
                expandIcon={<ChevronRightIcon  />}
                aria-controls={`foreign-registrations-content-${companyId}`}
                id={`foreign-registrations-header-${companyId}`}
            >
                <Typography variant="h6">Foreign Registrations</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails style={{ backgroundColor: '#f0f0f0', padding: '16px' }}>
                <div className={"serviceMetaData"}>
                    {(!foreignRegistrations || foreignRegistrations.length === 0) ? (
                        <div style={{ textAlign: 'center' }}>
                            <Typography variant="body1" style={{ marginBottom: '8px' }}>
                                No foreign registrations available.
                            </Typography>
                            <Link to={`/companies/update-a-company/${companyId}`}>
                                <button className="wooButtons">Add Foreign Registration</button>
                            </Link>
                        </div>
                    ) : (
                        <>
                            <ul className={"metaDataList"}>
                                <li><span><strong>State</strong></span></li>
                                <li><span><strong>Company Name in Registration</strong></span></li>
                                <li><span><strong>Date of Registration</strong></span></li>
                                <li><span><strong>Actions</strong></span></li>
                            </ul>
                            {foreignRegistrations.map((registration, index) => {
                                const { company_name_in_foreign_registration, state, date_of_registration } = registration;
                                const displayedCompanyName = company_name_in_foreign_registration || companyTitle;
                                const nameStatus = registration.company_name_differs_in_foreign_state ? "(Differs)" : "(Same)";
//style={{ paddingBottom: "8px" }}
                                return (
                                    <ul className={"metaDataList"} key={`foreign-registration-${index}`} >
                                        <li><span>{state}</span></li>
                                        <li><span>{displayedCompanyName} {nameStatus}</span></li>
                                        <li><span>{date_of_registration}</span></li>
                                        <li>
                                            <span>
                                                <Link to={`/companies/update-a-company/${companyId}`}>
                                                    <button className="wooButtons">Edit</button>
                                                </Link>
                                            </span>
                                        </li>
                                    </ul>
                                );
                            })}
                        </>
                    )}
                </div>
            </StyledAccordionDetails>
        </Accordion>
    );
};


export const BeneficialOwners = ({ beneficialOwners, companyId }) => {
    return (
        <Accordion>
            <StyledAccordionSummary
                expandIcon={<ChevronRightIcon  />}
                aria-controls={`beneficial-owners-content-${companyId}`}
                id={`beneficial-owners-header-${companyId}`}
            >
                <Typography variant="h6">Beneficial Owners</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                <div className={"serviceMetaData"}>
                {(!beneficialOwners || beneficialOwners.length === 0) ? (
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="body1" style={{ marginBottom: '8px' }}>
                            No beneficial owners available.
                        </Typography>
                        <Link to={`/companies/update-a-company/${companyId}`}>
                            <button className="wooButtons">Add Beneficial Owner</button>
                        </Link>
                    </div>
                ) : (
                    <>
                        <ul className={"metaDataList"}>
                            <li><span><strong>Name</strong></span></li>
                            <li><span><strong>Email ID</strong></span></li>
                            <li><span><strong>Percentage Owned</strong></span></li>
                            <li><span><strong>Actions</strong></span></li>
                        </ul>
                        {beneficialOwners.map((owner, index) => {
                            const { first_name, last_name, email_id, percentage_owned } = owner;
                            const formatPercentage = (percentage) => percentage.endsWith('%') ? percentage : `${percentage}%`;

                            return (
                                <ul className={"metaDataList"} key={`beneficial-owner-${index}`}>
                                    <li><span>{first_name} {last_name}</span></li>
                                    <li><span>{email_id}</span></li>
                                    <li><span>{formatPercentage(percentage_owned)}</span></li>
                                    <li>
                                        <span>
                                            <Link to={`/companies/update-a-company/${companyId}`}>
                                                <button className="wooButtons">Edit</button>
                                            </Link>
                                        </span>
                                    </li>
                                </ul>
                            );
                        })}
                    </>
                )}
                </div>
            </StyledAccordionDetails>
        </Accordion>
    );
};



//export const RelatedOrders = ({ orderList, relatedOrders }) => {
//     let relatedOrdersArray = [];
//
//     if (Array.isArray(relatedOrders)) {
//         relatedOrdersArray = relatedOrders;
//     } else if (typeof relatedOrders === 'number') {
//         relatedOrdersArray = [relatedOrders];
//     } else if (relatedOrders && typeof relatedOrders === 'object') {
//         relatedOrdersArray = Object.keys(relatedOrders).map(key => parseInt(relatedOrders[key]));
//     }
//
//     const hasRelatedOrders = relatedOrdersArray.length > 0;
//
//     // Filter the orders that are not created via 'subscription'
//     const filteredOrderList = orderList.filter(order => order.created_via !== 'subscription');
//
//     // Prepare a list of related orders by matching IDs
//     const relatedOrderDetails = filteredOrderList.filter(order => relatedOrdersArray.includes(parseInt(order.id)));
//
//     // Display the component only if there are related orders
//     return relatedOrderDetails.length > 0 ? (
//         <div className={"relatedSubscriptionsBlock"}>
//             <div className={"relatedSubscriptionsHeader"}>
//                 <Typography variant="h5" style={{fontWeight: 600}}>Related Orders</Typography><br/>
//             </div>
//             <div className={"relatedSubscriptionsMeta"}>
//                 <ul className={"relatedSubscriptionsMetaHeader"}>
//                     <li>
//                         <span><strong>Order ID</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Service(s)</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Order Date</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Payment Method</strong></span>
//                     </li>
//                 </ul>
//                 {relatedOrderDetails.map((item) => {
//                     const {id: orderID, status, payment_method_title, date_created, line_items} = item;
//                     const orderDate = new Date(date_created.toString()).toLocaleDateString();
//                     const relatedStatus = status.replace(/-/g, "").toUpperCase();
//                     const relatedStatusColor = getStatusColor(relatedStatus);
//                     const RelatedIcon = getStatusIcon(relatedStatus);
//                     const displayValue = relatedStatus === "INPROGRESS" ? "IN-PROGRESS" : relatedStatus;
//
//                     const relatedOrderStatusTooltip = <Tooltip title={relatedStatus}><RelatedIcon style={{ color: relatedStatusColor }}/></Tooltip>;
//
//                     return (
//                         <ul className={"relatedSubscriptionsMetaHeader"} style={{paddingBottom: '5px'}}>
//                             <li>
//                                 <div style={{ display: 'flex', alignItems: 'center'}}>
//                                     <Link to={"/view-order/"+orderID} style={{color: '#536889', marginRight: '10px'}}>
//                                         <span className={"idNamelink"}>{orderID}</span>
//                                     </Link>
//                                     {relatedOrderStatusTooltip}
//                                 </div>
//                             </li>
//                             <li>
//                                 {line_items.map((e) => (
//                                     <span key={e.id}>{e.name}<br/></span>
//                                 ))}
//                             </li>
//                             <li>
//                                 <span>{orderDate}</span>
//                             </li>
//                             <li>
//                                 <span>{payment_method_title}</span>
//                             </li>
//                         </ul>
//                     );
//                 })}
//             </div>
//         </div>
//     ) : null;
// };

//export const ForeignRegistrations = ({ foreignRegistrations, companyTitle, companyId }) => {
//     if (!foreignRegistrations || foreignRegistrations.length === 0) {
//         return null; // No foreign registrations to display
//     }
//
//     return (
//         <Accordion>
//             <StyledAccordionSummary
//                 expandIcon={<ExpandMoreIcon />}
//                 aria-controls={`foreign-registrations-content-${companyId}`}
//                 id={`foreign-registrations-header-${companyId}`}
//             >
//                 <Typography variant="h6">Foreign Registrations</Typography>
//             </StyledAccordionSummary>
//             <StyledAccordionDetails style={{ backgroundColor: '#f0f0f0', padding: '16px' }}>
//                 <ul className={"relatedSubscriptionsMetaHeader"}>
//                     <li>
//                         <span><strong>State</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Company Name in Registration</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Date of Registration</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Actions</strong></span>
//                     </li>
//                 </ul>
//                 {foreignRegistrations.map((registration, index) => {
//                     const { company_name_in_foreign_registration, state, date_of_registration } = registration;
//                     const displayedCompanyName = company_name_in_foreign_registration || companyTitle;
//                     const nameStatus = registration.company_name_differs_in_foreign_state ? "(Differs)" : "(Same)";
//
//                     return (
//                         <ul className={"relatedSubscriptionsMetaHeader"} key={`foreign-registration-${index}`} style={{ paddingBottom: "8px" }}>
//                             <li>
//                                 <span>{state}</span>
//                             </li>
//                             <li>
//                                 <span>{displayedCompanyName} {nameStatus}</span>
//                             </li>
//                             <li>
//                                 <span>{date_of_registration}</span>
//                             </li>
//                             <li>
//                                 <span>
//                                     <Link to={`/companies/update-a-company/${companyId}`}>
//                                         <button className="wooButtons">Edit</button>
//                                     </Link>
//                                 </span>
//                             </li>
//                         </ul>
//                     );
//                 })}
//             </StyledAccordionDetails>
//         </Accordion>
//     );
// };

//export const ForeignRegistrations = ({ foreignRegistrations, companyTitle, companyId }) => {
//     if (!foreignRegistrations || foreignRegistrations.length === 0) {
//         return null; // No foreign registrations to display
//     }
//
//     return (
//         <div className={"relatedSubscriptionsBlock"}>
//             <div className={"relatedSubscriptionsHeader"}>
//                 <Typography variant="h5" style={{ fontWeight: 600 }}>
//                     Foreign Registrations
//                 </Typography>
//             </div>
//             <div className={"relatedSubscriptionsMeta"}>
//                 <ul className={"relatedSubscriptionsMetaHeader"}>
//                     <li>
//                         <span><strong>State</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Company Name in Registration</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Date of Registration</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Actions</strong></span>
//                     </li>
//                 </ul>
//                 {foreignRegistrations.map((registration, index) => {
//                     const {
//                         company_name_differs_in_foreign_state,
//                         company_name_in_foreign_registration,
//                         state,
//                         principal_address,
//                         date_of_registration,
//                         mailing_address_same_as_address,
//                         mailing_address,
//                     } = registration;
//
//                     // Determine the company name to display
//                     const displayedCompanyName = company_name_in_foreign_registration
//                         ? company_name_in_foreign_registration
//                         : companyTitle;
//
//                     const nameStatus = company_name_differs_in_foreign_state ? "(Differs)" : "(Same)";
//
//                     const principalAddressFormatted = formatAddress(principal_address);
//                     const mailingAddressFormatted = mailing_address_same_as_address
//                         ? "Mailing Address same as Principal Address"
//                         : formatAddress(mailing_address);
//
//                     return (
//                         <ul className={"relatedSubscriptionsMetaHeader"} key={`foreign-registration-${index}`} style={{ paddingBottom: "5px" }}>
//                             <li>
//                                 <span>{state}</span>
//                             </li>
//                             <li>
//                                 <span>
//                                     {displayedCompanyName} {nameStatus}
//                                 </span>
//                             </li>
//                             <li>
//                                 {date_of_registration}
//                             </li>
//                             <li>
//                                 <span>
//                                     <Link to={`/companies/update-a-company/${companyId}`}>
//                                         <button className="wooButtons">Edit</button>
//                                     </Link>
//                                 </span>
//                             </li>
//                         </ul>
//                     );
//                 })}
//             </div>
//         </div>
//     );
// };


//export const relatedSubscriptions = (subList,subs) => {
//
//     subs = subs || [];
//
//     return subs !== 'N/A' && subs.length > 0 ? (
//         <div className={"relatedSubscriptionsBlock"}>
//             <div className={"relatedSubscriptionsHeader"}>
//                 <Typography variant="h5" style={{fontWeight: 600}}>Related Subscriptions</Typography>
//             </div>
//             <div className={"relatedSubscriptionsMeta"}>
//                 <ul className={"relatedSubscriptionsMetaHeader"}>
//                     <li>
//                         <span><strong>Subscription ID</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Service(s)</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Renewal Date</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Payment Method</strong></span>
//                     </li>
//                 </ul>
//                 {subList.map((item)=>{
//                     const {parent_id, id: subId,line_items,status,date_created,next_payment_date_gmt,payment_method_title} = item;
//                     const dateForSub = new Date(date_created.toString()).toLocaleDateString();
//                     const renewalDateForSub = new Date(next_payment_date_gmt.toString()).toLocaleDateString();
//
//                     const relatedStatus = status.replace(/-/g, "").toUpperCase();
//                     const subStatusColor = getStatusColor(relatedStatus);
//                     const SubIcon = getStatusIcon(relatedStatus);
//                     const displayValue = relatedStatus === "INPROGRESS" ? "IN-PROGRESS" : relatedStatus;
//
//                     const relatedSubStatusTooltip = <Tooltip title={relatedStatus}><SubIcon style={{ color: subStatusColor }}/></Tooltip>;
//
//                     return subs.includes(subId) ? (
//                         <ul className={"relatedSubscriptionsMetaHeader"} style={{paddingBottom: '5px'}}>
//                             <li>
//                                 <div style={{ display: 'flex', alignItems: 'center'}}>
//                                     <Link to={"/view-subscription/"+subId} style={{color: '#536889', marginRight: '10px'}}>
//                                         <span className={"idNamelink"}>{subId}</span>
//                                     </Link>
//                                     {relatedSubStatusTooltip}
//                                 </div>
//                             </li>
//                             <li>
//                                 {line_items.map((e)=>{
//                                     const {name} = e;
//                                     return (
//                                         <span>{name}<br/></span>
//                                     )
//                                 })}
//                             </li>
//                             <li>
//                                 <span>{renewalDateForSub}</span>
//                             </li>
//                             <li>
//                                 {payment_method_title}
//                             </li>
//                         </ul>
//                     ) : (
//                         <div hidden="hidden">
//
//                         </div>
//                     )
//                 })}
//             </div>
//         </div>
//     ) : (
//         <div hidden="hidden">
//
//         </div>
//     )
// };

//export const BeneficialOwners = ({ beneficialOwners, companyId }) => {
//     if (!beneficialOwners || beneficialOwners.length === 0) {
//         return null; // No beneficial owners to display
//     }
//
//     return (
//         <Accordion>
//             <StyledAccordionSummary
//                 expandIcon={<ExpandMoreIcon />}
//                 aria-controls={`beneficial-owners-content-${companyId}`}
//                 id={`beneficial-owners-header-${companyId}`}
//             >
//                 <Typography variant="h6">Beneficial Owners</Typography>
//             </StyledAccordionSummary>
//             <StyledAccordionDetails>
//                 <ul className={"relatedSubscriptionsMetaHeader"}>
//                     <li>
//                         <span><strong>Name</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Email ID</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Percentage Owned</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Actions</strong></span>
//                     </li>
//                 </ul>
//                 {beneficialOwners.map((owner, index) => {
//                     const { first_name, last_name, email_id, percentage_owned } = owner;
//
//                     return (
//                         <ul className={"relatedSubscriptionsMetaHeader"} key={`beneficial-owner-${index}`}>
//                             <li>
//                                 <span>{first_name} {last_name}</span>
//                             </li>
//                             <li>
//                                 <span>{email_id}</span>
//                             </li>
//                             <li>
//                                 <span>{percentage_owned}</span>
//                             </li>
//                             <li>
//                                 <span>
//                                     <Link to={`/companies/update-a-company/${companyId}`}>
//                                         <button className="wooButtons">Edit</button>
//                                     </Link>
//                                 </span>
//                             </li>
//                         </ul>
//                     );
//                 })}
//             </StyledAccordionDetails>
//         </Accordion>
//     );
// };

//export const BeneficialOwners = ({ beneficialOwners }) => {
//     if (!beneficialOwners || beneficialOwners.length === 0) {
//         return null; // No beneficial owners to display
//     }
//
//     return (
//         <div className={"relatedSubscriptionsBlock"}>
//             <div className={"relatedSubscriptionsHeader"}>
//                 <Typography variant="h5" style={{ fontWeight: 600 }}>
//                     Beneficial Owners
//                 </Typography>
//             </div>
//             <div className={"relatedSubscriptionsMeta"}>
//                 <ul className={"relatedSubscriptionsMetaHeader"}>
//                     <li>
//                         <span><strong>Name</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Email ID</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Phone Number</strong></span>
//                     </li>
//                     <li>
//                         <span><strong>Percentage Owned</strong></span>
//                     </li>
//                 </ul>
//                 {beneficialOwners.map((owner, index) => {
//                     const {
//                         first_name,
//                         last_name,
//                         email_id,
//                         phone_number,
//                         percentage_owned,
//                         address,
//                         mailing_address_same_as_address,
//                         mailing_address,
//                     } = owner;
//
//                     const addressForOwner = formatAddress(address);
//                     const mailingAddressForOwner = mailing_address_same_as_address
//                         ? "Mailing Address same as Address"
//                         : formatAddress(mailing_address);
//
//                     return (
//                         <ul
//                             className={"relatedSubscriptionsMetaHeader"}
//                             key={`beneficial-owner-${index}`}
//                             style={{ paddingBottom: "5px" }}
//                         >
//                             <li>
//                                 <span>{first_name} {last_name}</span>
//                             </li>
//                             <li>
//                                 <span>{email_id}</span>
//                             </li>
//                             <li>
//                                 <span>{phone_number}</span>
//                             </li>
//                             <li>
//                                 <span>{percentage_owned}</span>
//                             </li>
//                         </ul>
//                     );
//                 })}
//             </div>
//         </div>
//     );
// };

// import "../../styles/style.scss";
import MUIDataTable, {ExpandButton} from "mui-datatables";
import {ThemeProvider} from "@mui/material/styles";
import {CacheProvider} from "@emotion/react";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import createCache from "@emotion/cache";
import {createTheme} from "@mui/material/styles";
import {TableCell, TableRow, Paper} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { relatedBillingDetailsAccordion } from "../orderList/ordersList";
import './subcriptionsList.css';
import formatCurrency from "../../functions/formatCurrency";
import parse from 'html-react-parser';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import generateSortableColumn from "../../components/functions/generateSortableColumn";
import {RelatedOrders} from "../orderList/ordersUtils";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion } from "@mui/material";
import { StyledAccordionSummary, StyledAccordionDetails } from "../orderList/ordersUtils";
import Collapse from '@mui/material/Collapse';


import {MyExpansionChip,MyChip,getStatusIcon,statusIconMap,getStatusColor,statusColorMap,useStyles} from "../orderList/ordersList";

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});

export default function SubscriptionsList(props){

    const subscriptionList = props.subList;
    const orderList = props.orders;

    const [rows, setRows] = useState([]);
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('none');

    const subStatusColorMap = {
        ACTIVE: '#c6e1c6',
        EXPIRED: '#bd94ae',
        PENDING: '#c6e1c6',
        ONHOLD: '#f8dda7',
        CANCELLED: '#c7b1b1'
    };

    const getSubStatusColor = (status) => {
        return subStatusColorMap[status] || 'black'; // return black as default color if status not in map
    };

    const subStatusIconMap = {
        ACTIVE: VerifiedIcon,
        PENDING: AccessTimeFilledIcon,
        EXPIRED: HighlightOffIcon,
        ONHOLD: RemoveCircleIcon,
        CANCELLED: CancelIcon,
    }

    const getSubStatusIcon = (status) => {
        return subStatusIconMap[status] || AcUnitIcon;
    }

    useEffect(() => {
        const storedExpandedRows = sessionStorage.getItem("expandedSubscriptionRows");

        if (storedExpandedRows) {
            setRows(JSON.parse(storedExpandedRows));
        }
    }, []);

    const getFixedWidthProps = (width) => {
        return () => ({
            style: { width, maxWidth: width, minWidth: width }
        });
    };

    const data = subscriptionList && subscriptionList.map(item => {
        let sub = "";
        let quantity = 0;

        // Extracting meta_data and getting company name
        const companyMeta = item.line_items.flatMap(lineItem =>
            lineItem.meta_data.filter(meta => meta.key === "Company")
        );
        const companyName = companyMeta.length > 0 ? companyMeta[0].value : "N/A";

        const { id, next_payment_date_gmt, line_items, payment_url, needs_payment } = item;
        const date = new Date(next_payment_date_gmt.toString()).toLocaleDateString();
        const itemTotal = formatCurrency(item.total);

        const SubStatusIcon = getSubStatusIcon(item.status.replace(/-/g, "").toUpperCase());
        const subStatusColor = getSubStatusColor(item.status.replace(/-/g, "").toUpperCase());

        const subStatusTooltip = (
            <Tooltip title={item.status.replace(/-/g, "").toUpperCase()}>
                <SubStatusIcon style={{ color: subStatusColor }} />
            </Tooltip>
        );

        const subIdStatus = (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <span>{id}</span>
                {subStatusTooltip}
            </div>
        );

        line_items.forEach((e) => {
            sub = sub !== "" ? `${sub} & ${e.name}` : e.name;
            quantity += 1;
        });

        const paymentURL = () => {
            const payOrderCheckout = () => {
                window.location.assign(payment_url);
            };
            return (
                <div className={"actionButtons"}>
                    <Link to={`/view-subscription/${id}`}>
                        <button className={"wooButtons"}>View</button>
                    </Link>
                </div>
            );
        };

        return [id, subIdStatus, sub, companyName, date, itemTotal, paymentURL];
    });

    const columns = [
        {
            name: "ID", // This is the actual column name you might not want to show
            options: {
                display: false, // Hide the column that only contains the plain ID
            }
        },
        generateSortableColumn("Subscription ID", 0, {
            filter: false,
            sortThirdClickReset: true,
            sortDescFirst: true,
            setCellProps: getFixedWidthProps('14.00%')
        }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Service(s)", 1, {
            filter: true,
            sortThirdClickReset: true,
            sortDescFirst: true,
            setCellProps: () => ({
                style: {
                    ...getFixedWidthProps('30.00%').style,
                    textAlign:'left'
                }
            }),
            customBodyRender: (value) => (
                value.split("\n").map((item, index) => (
                    <div key={index}>
                        {item}
                        {index !== value.split("\n").length - 1 && <br />}
                    </div>
                ))
            )
        }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Company", 2, {
            filter: true,
            sortThirdClickReset: true,
            sortDescFirst: true,
            setCellProps: getFixedWidthProps('16.00%')
        }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Renewal Date", 3, {
            filter: true,
            sortThirdClickReset: true,
            sortDescFirst: true,
            setCellProps: getFixedWidthProps('15.00%')
        }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Total", 5, {
            filter: false,
            sortThirdClickReset: true,
            sortDescFirst: true,
            setCellProps: getFixedWidthProps('10.00%')
        }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Actions", 6, {
            filter: false,
            sortThirdClickReset: true,
            setCellProps: getFixedWidthProps('15.00%')
        }, false, sortedColumn, setSortedColumn, sortDirection, setSortDirection)
    ];

    const theme = createTheme({
        overrides: {
            MUIDataTableSelectCell: {
                expandDisabled: {
                    // Soft hide the button.
                    visibility: "hidden"
                }
            }
        }
    });

    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        selectableRows: "none",
        fixedHeader: true,
        fixedSelectColumn: true,
        selectableRowsOnClick: false,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        rowsExpanded: rows,
        rowsPerPageOptions: [10, 15, 20 , 25, 30],
        onTableInit: () => {
            const storedExpandedRows = sessionStorage.getItem("expandedSubscriptionRows");
            if (storedExpandedRows) {
                setRows(JSON.parse(storedExpandedRows));
            }
        },
        setRowProps: (row, dataIndex, rowIndex) => {
            if(rowIndex % 2 === 0 ){
                return {
                    style: {
                        backgroundColor: 'white',
                        fontSize: '18px'
                    },
                }
            }
            else{
                return{
                    style: {
                        backgroundColor: '#f0f0f1',
                        fontSize: '18px'
                    }
                }
            }
        },
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;

            return subscriptionList && subscriptionList.map((item) =>{
                const { id,status,date_created,payment_method_title, line_items, parent_id,relatedOrders,
                    billing: {company,first_name,last_name,address_1,address_2,phone,email,state,city,postcode}} = item;
                const dateForSub = new Date(date_created.toString()).toLocaleDateString();
                console.log("Row Index:", rowMeta.rowIndex, "Rows State:", rows);

                return (rowData[0] === id) ? (
                    <TableRow>
                        <TableCell colSpan={colSpan} className={"expandable-row"}>
                            <Collapse in={true} timeout="auto" unmountOnExit>
                                <Paper className={"paperAddress"}>
                                    <div className={"order-sub-details-address"}>
                                        {line_items.map((i, index) => {
                                            const { name, meta_data } = i;
                                            return (
                                                <Accordion key={`subscription-item-${index}`}>
                                                    <StyledAccordionSummary
                                                        expandIcon={<ChevronRightIcon />}
                                                        aria-controls={`subscription-details-content-${id}-${index}`}
                                                        id={`subscription-details-header-${id}-${index}`}
                                                    >
                                                        <Link to={`/view-subscription/${id}`} style={{ color: '#536889', textDecoration: 'none' }}>
                                                            <Typography variant="h5" style={{ fontWeight: 600 }} className={"idNamelink"}>
                                                                {`${id} - ${name}`} {/* Subscription ID - Service Name */}
                                                            </Typography>
                                                        </Link>
                                                    </StyledAccordionSummary>
                                                    <StyledAccordionDetails>
                                                        <div className={"serviceMetaData"}>
                                                            <ul className={"metaDataList"}>
                                                                {meta_data.filter(({ key }) => key.charAt(0) !== "_").slice(0, 4).map((c, i) => {
                                                                    const { display_key, value } = c;
                                                                    return (
                                                                        <li key={`meta-${i}`} className={"metadataTitle"}>
                                                                        <span>
                                                                            <strong>{display_key}</strong>
                                                                            <br />
                                                                            {parse(value)}
                                                                        </span>
                                                                        </li>
                                                                    );
                                                                })}

                                                                {[...Array(Math.max(0, 4 - meta_data.filter(({ key }) => key.charAt(0) !== "_").length))].map((_, i) => (
                                                                    <li key={`placeholder-${i}`} className={"metadataTitle"}>
                                                                        <span>&nbsp;</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </StyledAccordionDetails>
                                                </Accordion>
                                            );
                                        })}

                                        <RelatedOrders
                                            orderList={orderList}
                                            relatedOrders={relatedOrders}
                                        />

                                        {relatedBillingDetailsAccordion(first_name,last_name,company,
                                            address_1,address_2,city,state,postcode,email,phone)}
                                    </div>
                                </Paper>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                ) : (
                    <div hidden="hidden">

                    </div>
                )
            })

        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
            const expandedRowIndexes = allExpanded.map(row => row.dataIndex);
            sessionStorage.setItem('expandedSubscriptionRows', JSON.stringify(expandedRowIndexes));
        },
    };

    return  (
        <div className={"pageView"}>
            <div className={"pageWrapper"}>
                <Typography variant="h4" className={"pageTitle"}>My Subscriptions List</Typography>
                <CacheProvider value={muiCache}>
                    <MUIDataTable
                        className="customTableHeader"
                        title={""}
                        data={data}
                        columns={columns}
                        options={options}
                        style={{ tableLayout: 'fixed' }}
                    />
                </CacheProvider>
            </div>
        </div>
    );
}


// renderExpandableRow: (rowData, rowMeta) => {
//
//             const colSpan = rowData.length + 1;
//             const subscriptionData = subscriptionList[rowMeta.dataIndex];
//             const isExpanded = expandedRows.includes(rowMeta.dataIndex);
//
//             return subscriptionList && subscriptionList.map((item) =>{
//                 const { id,status,date_created,payment_method_title, line_items, parent_id,relatedOrders,
//                     billing: {company,first_name,last_name,address_1,address_2,phone,email,state,city,postcode}} = item;
//                 const dateForSub = new Date(date_created.toString()).toLocaleDateString();
//
//                 return (rowData[0] === id) ? (
//                     <TableRow>
//                         <TableCell colSpan={colSpan}>
//                             <Collapse in={true} timeout={300} unmountOnExit>
//                                 <Paper className={"paperAddress"}>
//                                     <div className={"order-sub-details-address"}>
//
//                                         {line_items.map((i, index) => {
//                                             const { name, meta_data } = i;
//
//                                             return (
//                                                 <Accordion key={`subscription-item-${index}`}>
//                                                     <StyledAccordionSummary
//                                                         expandIcon={<ChevronRightIcon />}
//                                                         aria-controls={`subscription-details-content-${id}-${index}`}
//                                                         id={`subscription-details-header-${id}-${index}`}
//                                                     >
//                                                         <Link to={`/view-subscription/${id}`} style={{ color: '#536889', textDecoration: 'none' }}>
//                                                             <Typography variant="h5" style={{ fontWeight: 600 }} className={"idNamelink"}>
//                                                                 {`${id} - ${name}`} {/* Subscription ID - Service Name */}
//                                                             </Typography>
//                                                         </Link>
//                                                     </StyledAccordionSummary>
//                                                     <StyledAccordionDetails>
//                                                         <div className={"serviceMetaData"}>
//                                                             <ul className={"metaDataList"}>
//                                                                 {/* Show up to 4 metadata entries */}
//                                                                 {meta_data.filter(({ key }) => key.charAt(0) !== "_").slice(0, 4).map((c, i) => {
//                                                                     const { display_key, value } = c;
//                                                                     return (
//                                                                         <li key={`meta-${i}`} className={"metadataTitle"}>
//                                                                     <span>
//                                                                         <strong>{display_key}</strong>
//                                                                         <br />
//                                                                         {parse(value)}
//                                                                     </span>
//                                                                         </li>
//                                                                     );
//                                                                 })}
//
//                                                                 {/* Placeholder list items for consistent spacing */}
//                                                                 {[...Array(Math.max(0, 4 - meta_data.filter(({ key }) => key.charAt(0) !== "_").length))].map((_, i) => (
//                                                                     <li key={`placeholder-${i}`} className={"metadataTitle"}>
//                                                                         <span>&nbsp;</span>
//                                                                     </li>
//                                                                 ))}
//                                                             </ul>
//                                                         </div>
//                                                     </StyledAccordionDetails>
//                                                 </Accordion>
//                                             );
//                                         })}
//
//                                         <RelatedOrders
//                                             orderList={orderList}
//                                             relatedOrders={relatedOrders}
//                                         />
//
//                                         {relatedBillingDetailsAccordion(first_name,last_name,company,
//                                             address_1,address_2,city,state,postcode,email,phone)}
//                                     </div>
//                                 </Paper>
//                             </Collapse>
//                         </TableCell>
//                     </TableRow>
//                 ) : (
//                     <div hidden="hidden">
//
//                     </div>
//                 )
//             })
//
//         },


// generateSortableColumn("Status", 4, {
//     filter: false,
//     sortThirdClickReset: true,
//     sortDescFirst: true,
//     setCellProps: getFixedWidthProps('15.00%'),
//     customBodyRender: (value) => {
//         const color = getSubStatusColor(value);
//         const icon = getSubStatusIcon(value);
//         return (
//             <span className={"statusIcon"}>
//                     <MyChip label={value} icon={icon} color={color}/>
//                 </span>
//         );
//     }
// }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),


//<div className={"order-sub-details-address"}>
//                                     {line_items.map((i) => {
//                                         const {name, meta_data} = i;
//
//                                         return (
//                                             <div className={"orderMetaData"}>
//                                                 <div className={"serviceName"}>
//                                                     <Link to={"/view-subscription/"+id} style={{color: '#536889'}}>
//                                                         <Typography variant="h5" style={{fontWeight: 600}} className={"idNamelink"}>{id}-{name}</Typography><br/>
//                                                     </Link>
//                                                 </div>
//                                                 <div className={"serviceMetaData"}>
//                                                     <ul className={"metaDataList"}>
//                                                         {meta_data.filter(({ key }) => key.charAt(0) !== "_").slice(0,4).map((c)=>{
//                                                             let {key,display_key, value} = c;
//
//                                                             return key.charAt(0) !== "_" ? (
//                                                                 <li className={"metadataTitle"}>
//                                                                         <span>
//                                                                             <strong>{display_key}</strong>
//                                                                             <br/>
//                                                                             {parse(value)}
//                                                                         </span>
//                                                                 </li>
//                                                             ) : (
//                                                                 <div hidden="hidden">
//                                                                     Sorry
//                                                                 </div>
//                                                             )
//                                                         })}
//
//                                                         {[...Array(Math.max(0, 4 - meta_data.filter(({ key }) => key.charAt(0) !== "_").length))].map((_, i) => (
//                                                             <li className={"metadataTitle"} key={`placeholder-${i}`}>
//                                                                 <span>&nbsp;</span>
//                                                             </li>
//                                                         ))}
//                                                     </ul>
//                                                 </div>
//                                             </div>
//                                         )
//                                     })}
//
//                                     <RelatedOrders
//                                         orderList={orderList}
//                                         relatedOrders={relatedOrders}
//                                     />
//
//                                     {relatedBillingDetailsAccordion(first_name,last_name,company,
//                                         address_1,address_2,city,state,postcode,email,phone)}
//                                 </div>


//                                    <Accordion>
//                                         <StyledAccordionSummary
//                                             expandIcon={<ExpandMoreIcon />}
//                                             aria-controls={`order-details-content-${id}`}
//                                             id={`order-details-header-${id}`}
//                                         >
//                                             <Link to={`/view-order/${id}`} style={{ color: '#536889', textDecoration: 'none' }}>
//                                                 <Typography variant="h5" style={{ fontWeight: 600 }} className={"idNamelink"}>
//                                                     {`Order ID: ${id}`}
//                                                 </Typography>
//                                             </Link>
//                                         </StyledAccordionSummary>
//                                         <StyledAccordionDetails>
//                                             {/* Order Services Section (loop remains outside individual accordions) */}
//                                             {line_items.map((i, index) => {
//                                                 const { name, meta_data } = i;
//
//                                                 return (
//                                                     <div key={`order-item-${index}`} className={"orderMetaData"}>
//                                                         <div className={"serviceName"}>
//                                                             <Typography variant="h6">
//                                                                 <strong>Service Name:</strong> {name}
//                                                             </Typography>
//                                                         </div>
//                                                         <div className={"serviceMetaData"}>
//                                                             <ul className={"metaDataList"}>
//                                                                 {/* Display up to 4 metadata entries */}
//                                                                 {meta_data.filter(({ key }) => key.charAt(0) !== "_").slice(0, 4).map((c, i) => {
//                                                                     const { display_key, value } = c;
//                                                                     return (
//                                                                         <li key={`meta-${i}`} className={"metadataTitle"}>
//                                                                     <span>
//                                                                         <strong>{display_key}</strong>
//                                                                         <br />
//                                                                         {parse(value)}
//                                                                     </span>
//                                                                         </li>
//                                                                     );
//                                                                 })}
//
//                                                                 {/* Placeholder list items to maintain consistent spacing */}
//                                                                 {[...Array(Math.max(0, 4 - meta_data.filter(({ key }) => key.charAt(0) !== "_").length))].map((_, i) => (
//                                                                     <li key={`placeholder-${i}`} className={"metadataTitle"}>
//                                                                         <span>&nbsp;</span>
//                                                                     </li>
//                                                                 ))}
//                                                             </ul>
//                                                         </div>
//                                                     </div>
//                                                 );
//                                             })}
//                                         </StyledAccordionDetails>
//                                     </Accordion>
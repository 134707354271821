import React, {useEffect, useState, useCallback} from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { useAuth0 } from '@auth0/auth0-react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import {Divider} from "@mui/material";
import { FormControlLabel, Switch } from '@mui/material';
import ProtectedComponent from "./auth/protected-route";
import Sidebar from "./components/sidebar/Sidebar";
import NewSidebar from "./components/sidebar/NewSidebar";
import Topbar from "./components/Topbar/Topbar";
import ContentLoading from "./components/contentLoading";
import Loading from "./components/loading";
import AuthenticationButton from "./components/authentication-button"
import SimpleModal from './SimpleModal';
import OrdersList from "./Pages/orderList/ordersList";
import HomePage from "./Pages/home/HomePage";
import SubscriptionsList from "./Pages/subscriptionList/SubscriptionsList";
import CompaniesList from "./Pages/companies/Companies";
import AdminRequests from "./Pages/adminRequests/AdminRequests";
import AddSupportRequests from "./Pages/adminRequests/AddSupportRequest/AddSupportRequest";
import AddCompanies from "./Pages/companies/AddCompanies";
import UpdateCompanies from "./Pages/companies/UpdateCompanies";
import PaymentMethods from "./Pages/paymentMethods/PaymentMethods";
import MyProfile from "./Pages/myProfile/MyProfile"
import Address from "./Pages/address/Address";
import Order from "./Pages/order/Order";
import Subscription from "./Pages/subscription/Subscription";
import NeedHelp from "./Pages/needHelp/NeedHelp";
import BillingAddress from "./Pages/billingAddress/BillingAddress";
import AddPaymentMethod from "./Pages/paymentMethods/AddPaymentMethod";
import ProfileChange from "./Pages/changeProfile/ProfileChange";
import ShippingAddress from "./Pages/shippingAddress/ShippingAddress";
import {
    getUserDetails,
    getOrders,
    getSubscriptions,
    getSwitchBackUrl,
    getPaymentMethods,
    getHelpData,
    getCompanies,
    postJiraIssuesSearch,
    getSubCancelMsg } from "./actions";
import {makeDefaultOnRevalidate} from './functions/makeDefaultOnRevalidating';
import {deletePreviousPaymentMethod} from './functions/deletePreviousPaymentMethod';
import Banner from "./components/banner/Banner";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Demo from "./Pages/adminRequests/DemoTable";

export default function AppWrapper(props){

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { isLoading, isAuthenticated, user, loginWithRedirect, error } = useAuth0();
    const {name, email, picture ,sub} = user;
    const [switchedId, setSwitchedId] = useState(0);
    const userDetails =  useSelector((state) => state.userDetailsState.userDetails);
    const orders =  useSelector((state) => state.ordersState.orders);
    const subscriptionList =  useSelector((state) => state.subscriptionsState.subscriptions);
    const changePaymentMethodURL = useSelector((state) => state.subscriptionsState.changePaymentMethodURL);
    const paymentMethods =  useSelector((state) => state.paymentMethodsState.paymentMethods);
    const needHelpData = useSelector((state) => state.userMetaState.needHelp);
    const companiesList = useSelector((state) => state.userMetaState.companies);
    const switchUrl =  useSelector((state) => state.userDetailsState.switchBackUrl);
    const expandedSubscriptions = useSelector((state) => state.userMetaState.expandedSubscriptions);
    const userDetailsLoad =  useSelector((state) => state.userDetailsState.loading);
    const subscriptionLoad = useSelector((state) => state.subscriptionsState.loading);
    const jiraIssuesLoad = useSelector((state) => state.jiraIssuesState.loading);
    const paymentMethodsLoad =  useSelector((state) => state.paymentMethodsState.loading);
    const ordersLoad = useSelector((state) => state.ordersState.loading);
    const userMetaLoad = useSelector((state) => state.userMetaState.loading);
    const jiraIssuesList = useSelector((state) => state.jiraIssuesState.issues);
    const cancelledSubs = useSelector((state) => state.jiraIssuesState.CancelSubs);
    const CancelSubMsg = useSelector((state) => state.jiraIssuesState.SubCancellationMsg);
    const issueAdded = useSelector((state) => state.jiraIssuesState.issueAdded);

    const [id, setId] = useState(props.userId);
    const [isAdmin, setAdmin] = useState(props.isAdmin);
    const [queryParams, setQueryParams] = useState(props.queryParams);
    const [switchBackUrl, setSwitchBackUrl] = useState('');
    const [load, setLoader] = useState(false);
    const { cc: creditCards, pp: paypalPayments } = paymentMethods;
    const [wcsnonce, setWcsnonce] = useState('0');
    const [activeSubs, setActiveSubs] = useState([]);
    const [userSwitched, setUserSwitched] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const transformBillingInfo = (userDetails) => {
        if (userDetails && userDetails.billing) {
            return {
                billTo: {
                    firstName: userDetails.billing.first_name || "",
                    lastName: userDetails.billing.last_name || "",
                    company: userDetails.billing.company || "",
                    address: userDetails.billing.address_1 + ' ' + (userDetails.billing.address_2 || ""),
                    city: userDetails.billing.city || "",
                    state: userDetails.billing.state || "",
                    zip: userDetails.billing.postcode || "",
                    country: userDetails.billing.country || "",
                    phoneNumber: userDetails.billing.phone || "000-000-0000",
                    faxNumber: ""
                }
            };
        }
        return null;
    };

    const billingInfo = transformBillingInfo(userDetails);

    const getCounter = () => {
        {creditCards && creditCards.map((item)=>{
            const { subscriptions  } = item;
            if(subscriptions !== 'N/A'){
                subscriptions.map((item)=>{
                    setActiveSubs(
                        activeSubs => [...activeSubs, item]
                    )
                })
            }
        })}
    };

    const getUniqueProductNames = (orders) => {
        const productSet = new Set();
        if (Array.isArray(orders)) {
            orders.forEach((item) => {
                if (Array.isArray(item.line_items)) {
                    item.line_items.forEach((line_item) => {
                        productSet.add(line_item.name.trim());
                    });
                }
            });
        }
        return Array.from(productSet).sort();
    };

    const clearHashedQueryParams = () => {
        setQueryParams({});
        setUserSwitched(false);
        setSwitchedId(id);
        sessionStorage.removeItem('switchToQueryParams');
        const decodeUrl = decodeURIComponent(switchBackUrl).replaceAll('&amp;', '&');
        window.location.replace(decodeUrl);
    };

    const redirectToPathWithoutQueryParams = () => {
        navigate(location.pathname, { replace: true });
    };

    const filterNames = getUniqueProductNames(orders);
    const encodedString = sessionStorage.getItem('switchToQueryParams');
    const expiry = localStorage.getItem('expiry');
    const lastFourDigits = localStorage.getItem('last4');
    const changedPayment = localStorage.getItem('changedPayment');
    const madeDefault = localStorage.getItem('madeDefault');
    const deletePrevious = localStorage.getItem('deletePrevious');
    const site_url = process.env.REACT_APP_WP_SITE_URL;
    const jiraProjectKey = process.env.REACT_APP_JIRA_PROJECT_KEY;
    const jiraIssueType = process.env.REACT_APP_JIRA_ISSUE_TYPE;
    const jiraSearchString = email + '-' + id;

    const fetchData = async (isAdmin,id,queryParams) => {
        let userId = id;
        let switchUser = false;

        if ( isAdmin && encodedString) {
            const jsonString = atob(encodedString);
            let queryParamsObject;
            if (jsonString !== "undefined") {
                try {
                    queryParamsObject = JSON.parse(jsonString);
                } catch (error) {
                    queryParamsObject = {};
                }
                if (queryParamsObject.user_switched === 'true' && queryParamsObject.user_id > 0 ) {
                    userId = queryParamsObject.user_id;
                    setSwitchedId(userId);
                    switchUser = true;
                }
            }
        }

        if (userId > 0) {
            await Promise.all([
                dispatch(getUserDetails(userId)),
                dispatch(getOrders(userId)),
                dispatch(getSubscriptions(userId)),
                dispatch(getPaymentMethods(userId)),
                dispatch(getHelpData(userId)),
                dispatch(getCompanies(userId)),
                //dispatch(postJiraIssuesSearch(userId, jiraProjectKey,jiraIssueType,jiraSearchString)),
                dispatch(getSubCancelMsg(userId))
            ]);
            getCounter();
            if (switchUser) {
                redirectToPathWithoutQueryParams();
                setUserSwitched(true);
                dispatch(getSwitchBackUrl(id, userId));
            }
        } else {
            setId(0);
        }
    };

    const shouldShowModal = (creditCards) => {
        if (!creditCards) return false;
        let hasFirstDataPayeezyGateway = false;
        for (let paymentMethod of creditCards) {
            if (paymentMethod.method.gateway === "authorize_net_cim_credit_card") {
                return false;
            }
            if (paymentMethod.method.gateway === "first_data_payeezy_gateway_credit_card" && paymentMethod.subscriptions !== "N/A") {
                hasFirstDataPayeezyGateway = true;
            }
        }
        return hasFirstDataPayeezyGateway;
    }

    useEffect(() => {
        setWcsnonce(creditCards?.[0]?._wcsnonce || "default_value");
        const allDataLoaded = !userDetailsLoad && !subscriptionLoad && !paymentMethodsLoad && !ordersLoad && !userMetaLoad;
        const showModalBasedOnCards = shouldShowModal(creditCards) && allDataLoaded;
        if (showModalBasedOnCards) {
            const timer = setTimeout(() => {
                setShowModal(true);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [creditCards, userDetailsLoad, subscriptionLoad, paymentMethodsLoad, ordersLoad, userMetaLoad]);

    useEffect(() => {
        if(isAuthenticated){
            fetchData(isAdmin,id,queryParams);
        }
    }, [encodedString, isAuthenticated, isLoading, queryParams]);

    useEffect(() => {
        if (switchUrl && typeof switchUrl === 'object' && 'switch_back_url' in switchUrl) {
            setSwitchBackUrl(switchUrl.switch_back_url);
        }
    }, [switchUrl]);

    useEffect(() => {
        if (userDetails.email) {
            const usersId = userSwitched ? switchedId : id;
            const jiraSearchString = `${userDetails.email}-${usersId}`;
            dispatch(postJiraIssuesSearch(id, jiraProjectKey, jiraIssueType, jiraSearchString));
        }
    }, [userDetails.email, id, switchedId, userSwitched]);

    const handleCreditCardUpdates = async () => {
        if (expiry && lastFourDigits) {
            try {
                await makeDefaultOnRevalidate(creditCards, expiry, lastFourDigits, dispatch, site_url, id);

                await deletePreviousPaymentMethod(creditCards, expiry, lastFourDigits, dispatch, site_url, id,changedPayment,madeDefault);

                if(changedPayment === 'true' && madeDefault === 'true' && (deletePrevious === 'true' || !deletePrevious)){
                    localStorage.removeItem('expiry');
                    localStorage.removeItem('last4');
                    localStorage.removeItem('changedPayment');
                    localStorage.removeItem('madeDefault');
                }
            } catch (error) {
                console.error('An error occurred while updating payment methods:', error);
            }
        }
    };

    useEffect(() => {
        handleCreditCardUpdates();
    },[expiry, lastFourDigits, creditCards, dispatch, site_url, paymentMethods, id, changedPayment,madeDefault,deletePrevious]);

    if (isLoading) {
        return <Loading />;
    }

    const customerProfileId = subscriptionList && subscriptionList.length > 0 ? subscriptionList[0].customerProfileId : 'N/A';

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            <div className={"layout"}>
                <Banner />
                <Topbar />
            </div>
            <Divider variant="middle" />
            {userSwitched && (
                <button className="wooButtons" onClick={clearHashedQueryParams}>Switch back to {name}</button>
            )}
            <br/>
            <div className={"container"}>
                <Sidebar id={userSwitched ? switchedId : id}/>
                <Routes>
                    {userDetailsLoad || paymentMethodsLoad || userMetaLoad ? (
                        <>
                            <Route path='/' element={<ContentLoading/>} />
                            <Route path="/profile" element={<ContentLoading/>}/>
                            <Route path="/edit-address" element={<ContentLoading/>}/>
                            <Route path="/edit-account" element={<ContentLoading/>}/>
                            <Route path="/help" element={<ContentLoading/>}/>
                            <Route path="/edit-address/billing" element={<ContentLoading/>}/>
                            <Route path="/edit-address/shipping" element={<ContentLoading/>}/>
                        </>
                    ) : (
                        <>
                            <Route exact path="/" element={<HomePage orders={orders} subList={subscriptionList} details={userDetails} id={userSwitched ? switchedId : id}/>}/>
                            {/*<Route exact path="/" element={<Demo/>}/>*/}
                            <Route path="/profile" element={<MyProfile details={userDetails} id={userSwitched ? switchedId : id}/>}/>
                            <Route path="/edit-address" element={<Address details={userDetails}/>}/>
                            <Route path="/edit-account" element={<ProfileChange id={userSwitched ? switchedId : id} details={userDetails}/>}/>
                            <Route path="/help" element={<NeedHelp orders={orders} needHelp={needHelpData}/>}/>
                            <Route path="/edit-address/billing" element={<BillingAddress id={userSwitched ? switchedId : id} details={userDetails}/>}/>
                            <Route path="/edit-address/shipping" element={<ShippingAddress id={userSwitched ? switchedId : id} details={userDetails}/>}/>
                        </>
                    )}
                    {subscriptionLoad || ordersLoad || paymentMethodsLoad || userMetaLoad ? (
                        <>
                            <Route path="/orders" element={<ContentLoading/>}/>
                            <Route path="/subscriptions" element={<ContentLoading/>}/>
                            <Route path="/view-order/:orderId" element={<ContentLoading/>}/>
                            <Route path="/view-subscription/:subscriptionId" element={<ContentLoading/>}/>
                            <Route path="/companies" element={<ContentLoading/>}/>
                            <Route path="/companies/add-a-company" element={<ContentLoading/>}/>
                            <Route path="/companies/update-a-company" element={<ContentLoading/>}/>
                        </>
                    ) : (
                        <>
                            <Route path="/orders" element={<OrdersList orders={orders} subList={subscriptionList} paymentMethods={paymentMethods} filterNames={filterNames}/>}/>
                            <Route path="/subscriptions" element={<SubscriptionsList subList={subscriptionList} orders={orders} />}/>
                            <Route path="/view-order/:orderId" element={<Order orders={orders} subList={subscriptionList}/>}/>
                            <Route path="/view-subscription/:subscriptionId" element={<Subscription subList={subscriptionList} orders={orders} paymentMethods={paymentMethods} details={userDetails} id={userSwitched ? switchedId : id} cancelledSubs={cancelledSubs} jiraIssueType={jiraIssueType} jiraSearchString={jiraSearchString} CancelSubMsg={CancelSubMsg}/>}/>
                            <Route path="/companies" element={<CompaniesList companies={companiesList} orders={orders} subList={subscriptionList} details={userDetails} paymentMethods={paymentMethods} id={userSwitched ? switchedId : id} />}/>
                            <Route path="/companies/add-a-company" element={<AddCompanies companies={companiesList} details={userDetails} id={userSwitched ? switchedId : id} />}/>
                            <Route path="/companies/update-a-company/:companyId" element={<UpdateCompanies companies={companiesList} details={userDetails} id={userSwitched ? switchedId : id} />}/>
                        </>
                    )}
                    {jiraIssuesLoad ? (
                        <>
                            <Route path="/admin-requests" element={<ContentLoading/>}/>
                            <Route path="/admin-requests/add-support-request" element={<ContentLoading/>}/>
                        </>
                    ) : (
                        <>
                            <Route path="/admin-requests" element={<AdminRequests jiraIssuesList={jiraIssuesList} companies={companiesList} orders={orders} subList={subscriptionList} details={userDetails} issueAdded={issueAdded} id={userSwitched ? switchedId : id} />}/>
                            <Route path="/admin-requests/add-support-request" element={<AddSupportRequests jiraIssuesList={jiraIssuesList} jiraIssueType={jiraIssueType} jiraSearchString={jiraSearchString} companies={companiesList} orders={orders} subList={subscriptionList} details={userDetails} issueAdded={issueAdded} id={userSwitched ? switchedId : id} />}/>
                        </>
                    )}
                    {paymentMethodsLoad ? (
                        <>
                            <Route path="/payment-methods" element={<ContentLoading/>}/>
                            <Route path="/payment-methods/add-payment-method" element={<ContentLoading/>}/>
                        </>
                    ) : (
                        <>
                            <Route path="/payment-methods" element={<PaymentMethods paymentMethods={paymentMethods} id={userSwitched ? switchedId : id} activeSubs={activeSubs}/>}/>
                            <Route path="/payment-methods/add-payment-method" element={<AddPaymentMethod id={userSwitched ? switchedId : id} paymentMethods={paymentMethods} customerProfileId={customerProfileId} billingInfo={billingInfo} changePaymentMethodURL={changePaymentMethodURL} wcsnonce={wcsnonce}/>}/>
                        </>
                    )}
                </Routes>
            </div>
            {showModal && (
                <SimpleModal
                    show={showModal}
                    onClose={handleCloseModal}
                    id={userSwitched ? switchedId : id}
                    customerProfileId={customerProfileId}
                    billingInfo={billingInfo}
                    changePaymentMethodURL={changePaymentMethodURL}
                    wcsnonce={wcsnonce}
                    onSubmitSuccess={handleCloseModal}
                />
            )}
        </div>
    )
}